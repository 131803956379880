<template>
  <div>
    <transition name="fade" appear>
      <div class="hover-btn" v-show="officialWebsite.showReturnTip" @click.stop="$vuetify.goTo(0, {})">
        <v-img src="@/../public/img/icons/menu/returnTop.png"></v-img>
      </div>
    </transition>
    <v-dialog v-model="dialog" content-class="susDialog">
      <div class="suspend-dialog">
        <!-- 联系我们 -->
        <div class="content-phone" @click.stop v-show="currentHover === 'phone'">
          <v-card color="transparent" class="position-r" flat>
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="@/../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-row class="pa-0 justify-center mt-4">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">联系我们</v-card-title>
            </v-row>
            <v-row class="pa-0 mt-5 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img contain src="@/../public/img/icons/menu/cooperation.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22 mt-2">商户合作</v-card-title>
                  <v-card-text class="pa-0 font-size-14 line-height-20 mt-1">business@ophyer.com</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-7 ml-6">
              <div class="d-inline width-40">
                <v-avatar size="40" rounded-2>
                  <v-img width="40" height="40" contain src="@/../public/img/icons/menu/consult.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n1">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-16 font-weight-bold line-height-22 mt-2">产品咨询/加盟</v-card-title>
                  <v-card-text class="pa-0 mt-1 font-size-14 line-height-20">4006189949</v-card-text>
                </v-card>
              </div>
            </v-row>
          </v-card>
        </div>
        <!-- 关注我们 -->
        <div class="content-follow" @click.stop v-show="currentHover === 'follow'">
          <div class="close-btn" @click.stop="handleCloseDialog">
            <v-img max-width="12" contain src="@/../public/img/icons/menu/h5_close_btn.png"></v-img>
          </div>
          <v-card color="transparent" flat>
            <v-row class="pa-0 mt-5 justify-center">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">关注我们</v-card-title>
            </v-row>
            <v-row class="pa-0 justify-center mt-1">
              <v-img src="@/../public/img/icons/menu/qrCode.png" max-width="236" contain></v-img>
              <v-card-title class="font-size-14 pa-0 line-height-20 color-666">关注飞天云动公众号</v-card-title>
            </v-row>
          </v-card>
        </div>
        <!-- 申请使用 -->
        <div class="content-tryout" @click.stop v-show="currentHover === 'tryout'">
          <v-card color="transparent" flat width="100%" class="position-r">
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="@/../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-card-title class="justify-center text-align-center font-size-18 pa-0 line-height-25 font-weight-bold">
              完善信息
              <br />专属顾问马上联系您
            </v-card-title>
            <v-card-text class="text-align-center font-size-12 line-height-17 pa-0 mt-2">4006189949</v-card-text>
            <v-form class="pa-0 mt-3">
              <v-container class="pa-0">
                <v-row class="pa-0">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    姓名
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的姓名" clearable :rules="rules.name" v-model="form.name"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    电话
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的电话" clearable :rules="rules.phone" v-model="form.phone"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">
                    公司名称
                    <span style="color: red;vertical-align: sub;">*</span>
                  </div>
                  <v-text-field class="width-per-100" placeholder="请输入您的公司名称" clearable persistent-hint :rules="rules.conpanyName" v-model="form.conpanyName"></v-text-field>
                </v-row>
                <v-row class="pa-0" style="margin-top: 10px;">
                  <div class="font-size-15 font-weight-bold line-height-21">地区</div>
                  <v-text-field class="width-per-100" placeholder="请输入您所在的地区" persistent-hint v-model="form.address"></v-text-field>
                </v-row>
                <v-row class="pa-0 justify-center mt-5">
                  <div class="tryout-btn" @click.stop="handleSaveTryout">提交</div>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </div>
        <!-- 我要代理 -->
        <div class="content-agent" @click.stop v-show="currentHover === 'agent'">
          <v-card color="transparent" class="position-r" flat>
            <div class="close-btn" @click.stop="handleCloseDialog">
              <v-img max-width="12" contain src="@/../public/img/icons/menu/h5_close_btn.png"></v-img>
            </div>
            <v-row class="pa-0 justify-center mt-5">
              <v-card-title class="pa-0 justify-center font-size-18 line-height-25 font-weight-bold">我要代理</v-card-title>
            </v-row>
            <div class="pa-0 mt-6 justify-center" style="text-align: center;">
              <div class="justify-center">
                <v-avatar size="40" rounded-2>
                  <v-img contain src="@/../public/img/icons/menu/agent-icon.png"></v-img>
                </v-avatar>
              </div>
              <div class="ml-3 mt-2">
                <v-card color="transparent" flat>
                  <!-- <v-card-title class="pa-0 font-size-15 font-weight-bold line-height-20" style="margin-left:122px">汪总</v-card-title> -->
                  <v-card-text class="pa-0 font-size-17 line-height-20 mt-2">
                    <a href="tel:139-1618-4657" style="color: #2573E2;text-decoration: none">4006189949</a>
                  </v-card-text>
                </v-card>
              </div>
              <div class="justify-center mt-5">
                <v-img src="@/assets/login/wangWexin.png" width="200" height="200" contin style="margin: 0 auto;"></v-img>
                <v-card-text class="text-align-center font-size-14 pa-0 mt-3 mb-12 color-666">扫一扫加我微信</v-card-text>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <v-footer
      color="#272b2e"
      width="100%"
      min-width="330"
      class="justify-center text-align-center font-size-12"
      style="color: rgba(174, 184, 200, 0.8);line-height: 17px;padding: 10px 5px;margin-bottom: 70px;"
    >
      <!--© COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京飞天云动科技股份有限公司版权所有 京公网安备11010502037637号-->
      © COPYRIGHT 2022 . ALL RIGHTS RESERVED. 京公网安备11010502037637号
      <br /><span style="margin-left:5px">京ICP备09083236号-2</span>
    </v-footer>
    <v-bottom-navigation :value="bottomMenuVal" app grow height="70" :class="[color === 'white' ? 'white' : 'black']">
      <v-btn @click.stop="handleSuspend('follow')">
        <div class="mt-1">关注我们</div>
        <v-img max-width="40" max-height="40" contain :src="color === 'white' ? 'https://h5.ophyer.cn/official_website/page/other/h5-btn01.png' : 'https://h5.ophyer.cn/official_website/page/other/h5-btn01-black.png'"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('phone')">
        <div class="mt-1">联系我们</div>
        <v-img max-width="40" max-height="40" contain :src="color === 'white' ? 'https://h5.ophyer.cn/official_website/page/other/h5-btn02.png' : 'https://h5.ophyer.cn/official_website/page/other/h5-btn02-black.png'"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('tryout')">
        <div class="mt-1">申请试用</div>
        <v-img max-width="40" max-height="40" contain :src="color === 'white' ? 'https://h5.ophyer.cn/official_website/page/other/h5-btn03.png' : 'https://h5.ophyer.cn/official_website/page/other/h5-btn03-black.png'"></v-img>
      </v-btn>
      <v-btn @click.stop="handleSuspend('agent')">
        <div class="mt-1">我要代理</div>
        <v-img max-width="40" max-height="40" contain :src="color === 'white' ? 'https://h5.ophyer.cn/official_website/page/other/h5-btn04.png' : 'https://h5.ophyer.cn/official_website/page/other/h5-btn04-black.png'"></v-img>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'H5OfficialWebsiteFooter',
  props: {
    color: { type: String, default: 'white' },
  },
  data: () => ({
    bottomMenuVal: 1,
    dialog: false,
    currentHover: '',
    form: {
      name: '',
      phone: '',
      conpanyName: '',
      address: ''
    },
    rules: {
      name: [value => !!value || '请输入姓名!'],
      phone: [value => !!value || '请输入电话!'],
      conpanyName: [value => !!value || '请输入公司名称!']
    },
  }),
  computed: {
    ...mapState(['officialWebsite'])
  },
  mounted() {

  },
  methods: {
    handleSuspend: function (type) {
      this.currentHover = type
      this.dialog = true
    },
    handleCloseDialog: function () {
      this.currentHover = ''
      this.dialog = false
    },
    handleSaveTryout: function () {
      if (!this.form.name || !this.form.phone || !this.form.conpanyName) {
        this.$lavMessage.warning('请填写必填项！')
        return
      }
      this.$lavMessage.success('提交成功！')
      this.currentHover = ''
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
  color: #969799 !important;
}


::v-deep .susDialog {
  overflow-y: unset;
}
.suspend-dialog {
  display: flex;
  justify-content: center;
  .close-btn {
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px !important;
    border: 1px solid #ffffff;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-phone {
    width: 300px;
    height: 230px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .close-btn {
      top: 300px;
    }
  }
  .content-agent {
    width: 300px;
    // height: 125px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .close-btn {
      top: 403px;
    }
  }
  .content-follow {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    padding-bottom: 18px;
    position: relative;
    img {
      width: 180px;
      height: 180px;
      object-fit: contain;
    }
    .close-btn {
      top: 340px;
    }
  }
  .content-tryout {
    max-width: 500px;
    min-width: 300px;
    width: calc(100vw - 40px);
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 20px 30px 20px 30px;
    position: fixed;
    top: 47% !important;
    //left: 50%;
    z-index: 999;
    transform: translate(0, -50%);
    background-color: #ffffff;
    .tryout-btn {
      width: 100%;
      height: 40px;
      background: #2d87ff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      letter-spacing: 8px;
      cursor: pointer;
    }
    .close-btn {
      width: 30px;
      height: 30px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 15px !important;
      border: 1px solid #ffffff;
      cursor: pointer;
      position: absolute;
      top: 530px;
      left: calc(50% - 15px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ::v-deep .v-dialog {
    box-shadow: unset;
  }
}
.hover-btn {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: fixed;
  bottom: calc(20% - 55px);
  right: 5px;
  z-index: 5;
  box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
  cursor: pointer;
}

.white {
  background: #fff !important;
}
.black {
  background: #242529 !important;
  .mt-1 {
    color: #fff !important;
  }
}
</style>
